import {API_URL} from "../../helpers/crud-helper/consts";
import axios from "axios";

const SUBSCRIBE_URL = `${API_URL}/subscribe`


const subscribe = (value: any): Promise<any> => {
    return axios
        .post(SUBSCRIBE_URL, value)
        .then((response) => response)
}

export {subscribe}