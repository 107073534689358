import React, {useState} from "react";


const About = () => {
    const [fullView, setFullView] = useState(false);
    return (
        <section className="position-relative" id="about-us">
            <div className={"container"}>
                <div className="row align-items-center ">
                    <div className="col-lg-6 col-md-12 mt-lg-5 mt-4">
                        <img src={'/assets/images/leadership.jpg'} alt="About Us" className="w-100"/>

                        <img src={'/assets/images/about-overlay.png'} alt="Olive Branch" className="overlay-right d-lg-block d-none"/>
                    </div>
                    <div className="col-lg-6 col-md-12 mt-lg-5 mt-4">
                        <div >
                            <h2 className={'fs-40 text-black'}>About The Founder</h2>
                            <div className="dark-light-color fs-16 lh-25 me-lg-5 me-0 pt-3 mb-4">
                                <p>
                                    Thuraya's journey began 15 years ago after graduating from the
                                    American University of Sharjah with a degree in Accountancy and
                                    started her first job as an auditor at Ernst & Young.
                                </p>

                                {fullView && (
                                    <>
                                        <br/>
                                        <p>
                                            Not too long after, she realized that this job is not her calling.
                                            A few years later, she decided to leave the entire industry and
                                            took on minor jobs in different industries in an attempt to
                                            discover her passion. After numerous trials and errors, Thuraya
                                            realized that sales was her true calling.
                                        </p>
                                        <br/>
                                        <p>
                                            She found a job at a prominent Insurance Brokerage firm and gained
                                            immense sales experience during her time there where she became a
                                            certified financial planner who sold many insurance policies to
                                            individuals.
                                        </p>
                                        <br/>
                                        <p>
                                            After being approached by a friend who had opened a real estate
                                            brokerage, she decided to shift to real estate and give it a try.
                                            And just like the skyscrapers in Dubai, she grew her professional
                                            experience in real estate and gained a big network of clients over
                                            the years who gave her the confidence that she can do it on her
                                            own.
                                        </p>
                                        <br/>
                                        <p>
                                            Thuraya’s humble beginnings taught her that nothing in life is
                                            easy and that starting a business requires strength and
                                            resilience, both qualities which she is proud to own.
                                        </p>
                                        <br/>
                                        <p>
                                            To Thuraya, her biggest achievement is becoming a mother and a
                                            business owner. Her goals include enhancing her family’s life
                                            and building a career in an industry that is extremely
                                            competitive and very male-dominated.
                                        </p>
                                    </>
                                )}


                                <br/>
                                <div className="signature">
                                    <p className="first fs-65">Thuraya</p>
                                    <p className="fs-14 fw-700">Abdulla Al-Dharwi</p>
                                </div>
                                {!fullView && (
                                    <div className="btn btn-black cursor-pointer p-40"
                                         onClick={() => setFullView(true)}>KNOW MORE</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
