import {useFormik} from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import React, {useState} from "react";
import {subscribe} from "../../core/newsletter/requests";


const initialValues = {
    email: "",
    name: "",
};
const newsletterSchema = Yup.object().shape({
    email: Yup.string()
        .email("Wrong email format")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Email is required"),
    name: Yup.string().required("Name is required"),
});
const NewsletterForm = () => {
    const [loading, setLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const formik = useFormik({
        initialValues,
        validationSchema: newsletterSchema,
        onSubmit: async (values, {setSubmitting}) => {
            setLoading(true);

            setSubmitting(true);
            try {

                await subscribe(values);

                setLoading(false);
                setIsActive(true);
                formik.resetForm();
                setTimeout(function () {
                    setIsActive(false);
                }, 3500);

            } catch (ex) {
                console.error(ex);
            } finally {
                setSubmitting(true);
                setLoading(false);
            }
        },
    });

    return (
        <form className="z-index-999"
              onSubmit={formik.handleSubmit}
              noValidate>

            <ul className="footerLinks ps-lg-0">
                <li>
                    <h6 className={'footerLinks text-lg-start text-start'}>Newsletter Subscription</h6>
                    <div className={` ${isActive ? " alert-box d-block" : "alert-box d-none"}`}>
                        <div className="alert alert-success alert-dismissible fade show p-2" role="alert">
                            Successfully Subscribed!
                        </div>
                    </div>
                </li>
                <li>

                    <div className="form-group row">
                        <div className="col-lg-6 col-md-12 pe-lg-0 mt-lg-0 mt-1">
                            <input type="text" placeholder={'Enter name'}  {...formik.getFieldProps("name")}
                                   className={clsx(
                                       "form-control newsletter",
                                       {
                                           "is-invalid":
                                               formik.touched.name && formik.errors.name,
                                       },
                                       {
                                           "is-valid":
                                               formik.touched.name && !formik.errors.name,
                                       })} name="name"/>
                        </div>
                        <div className="col-lg-6 col-md-12 mt-lg-0 mt-1">
                            <input type="email" placeholder={'Enter Email Address'}

                                   {...formik.getFieldProps("email")}
                                   className={clsx(
                                       "form-control newsletter",
                                       {
                                           "is-invalid":
                                               formik.touched.email && formik.errors.email,
                                       },
                                       {
                                           "is-valid":
                                               formik.touched.email && !formik.errors.email,
                                       })} name="email"/>
                        </div>

                    </div>
                </li>

            </ul>
            <div className={'form-group row justify-content-end'}>
                <div className="col-lg-6 col-6 ">

                    <button className="btn btn-primary w-100"
                            disabled={formik.isSubmitting || !formik.isValid}
                            type="submit">{!loading && (
                        <span className="indicator-label">
                                  Subscribe
                                </span>
                    )}
                        {loading && (
                            <span
                                className="indicator-progress"
                                style={{display: "block"}}
                            >
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                        )}</button>
                </div>
            </div>

        </form>
    );
};

export default NewsletterForm;
