// HeaderContext.tsx
import React, {createContext, useContext, useState} from "react";

type HeaderVariant = "default" | "listing" | "property";

interface HeaderContextProps {
    variant: HeaderVariant;
    setVariant: React.Dispatch<React.SetStateAction<HeaderVariant>>;
    logoColor: "dark" | "light-dark" | "white";
    setLogoColor: React.Dispatch<React.SetStateAction<"dark" | "light-dark" | "white">>;
}

const HeaderContext = createContext<HeaderContextProps | undefined>(undefined);

export const useHeaderContext = (): HeaderContextProps => {
    const context = useContext(HeaderContext);
    if (!context) {
        throw new Error("useHeaderContext must be used within a HeaderProvider");
    }
    return context;
};

export const HeaderProvider: React.FC<{ children?: React.ReactNode }> = ({
                                                                             children
                                                                         }) => {
    const [variant, setVariant] = useState<HeaderVariant>("default");
    const [logoColor, setLogoColor] = useState<"dark" | "light-dark" | "white">("white");

    const contextValue: HeaderContextProps = {
        variant,
        setVariant,
        logoColor,
        setLogoColor,
    };

    return (
        <HeaderContext.Provider value={contextValue}>
            {children}
        </HeaderContext.Provider>
    );
};
