import React, {FC, useState} from "react";
import {useFormik} from "formik";
import clsx from "clsx";
import * as Yup from "yup";
import Select from 'react-select';
import {contact} from "../../../core/home/requests";

type Props = {
    property?: any
}

const RequestConsultationForm: FC<Props> = ({property}) => {
    const [loading, setLoading] = useState(false);
    const initialValues = {
        email: "",
        name: "",
        message: "",
        type: "",
        resale_leasing_id: property.id

    };
    const types = [
        {value: '1', label: 'Buy'},
        {value: '2', label: 'Rent'}
    ];

    const requestConsultationSchema = Yup.object().shape({
        type: Yup.string().required("Type is required"),
        email: Yup.string()
            .email("Wrong email format")
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Email is required"),
        name: Yup.string().required("Name is required"),
        message: Yup.string().required("Description is required"),

    });
    const formik = useFormik({
        initialValues,
        validationSchema: requestConsultationSchema,
        onSubmit: async (values, {setSubmitting}) => {
            setSubmitting(true);
            try {
                setLoading(true);

                const {data} = await contact(values);

                if (data === 1) {
                    setLoading(false);

                    formik.resetForm();
                    setTimeout(function () {

                        // resetForm()
                    }, 3500);
                }
            } catch (ex) {
                console.error(ex);
            } finally {
                setSubmitting(true);
                setLoading(false);
            }
        },
    });
    return (
        <div className={'bg-white request-consultant  bdr-2'}>
            <div className={'p-4'}>
                <h2 className={'form-title'}>
                    Request a Consultation
                </h2>

                <form className="z-index-999"
                      onSubmit={formik.handleSubmit}
                      noValidate>
                    <div className={'form-group row pt-4'}>
                        <div className={'col-md-12'}>
                            <Select
                                className={clsx(
                                    "",
                                    {
                                        "invalid-select":
                                            formik.touched.type && formik.errors.type,
                                    },
                                    {
                                        "valid-select":
                                            formik.touched.type && !formik.errors.type,
                                    })}
                                closeMenuOnSelect={true}
                                placeholder={"Select Property type"}
                                options={types.map((e: {
                                    label: any;
                                    value: any
                                }) => ({label: e.label, value: e.value}))}
                                isSearchable={true}
                                onChange={(newValue: any) =>
                                    formik.setFieldValue('type', newValue.value)
                                }
                            />
                            {formik.touched.type && formik.errors.type && (
                                <div className="fv-plugins-message-container">
                                    <span role="alert">{formik.errors.type}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={'form-group row  pt-4'}>
                        <div className={'col-md-12'}>
                            <input type={'text'}  {...formik.getFieldProps("name")}
                                   className={clsx(
                                       "form-control  main-input bordered",
                                       {
                                           "is-invalid":
                                               formik.touched.name && formik.errors.name,
                                       },
                                       {
                                           "is-valid":
                                               formik.touched.name && !formik.errors.name,
                                       })} placeholder={'Name'}/>

                            {formik.touched.name && formik.errors.name && (
                                <div className="fv-plugins-message-container">
                                    <span role="alert">{formik.errors.name}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={'form-group row mt-4'}>
                        <div className={'col-md-12'}>
                            <input type={'email'}
                                   {...formik.getFieldProps("email")}
                                   className={clsx(
                                       "form-control main-input bordered",
                                       {
                                           "is-invalid":
                                               formik.touched.email && formik.errors.email,
                                       },
                                       {
                                           "is-valid":
                                               formik.touched.email && !formik.errors.email,
                                       })}
                                   placeholder={'Email Address'}/>

                            {formik.touched.email && formik.errors.email && (
                                <div className="fv-plugins-message-container">
                                    <span role="alert">{formik.errors.email}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={'form-group row mt-4'}>
                        <div className={'col-md-12'}>
                        <textarea     {...formik.getFieldProps("message")} className={clsx(
                            "form-control main-input bordered h-200",
                            {
                                "is-invalid":
                                    formik.touched.message && formik.errors.message,
                            },
                            {
                                "is-valid":
                                    formik.touched.message && !formik.errors.message,
                            })} placeholder={'Message'}/>

                            {formik.touched.message && formik.errors.message && (
                                <div className="fv-plugins-message-container">
                                    <span role="alert">{formik.errors.message}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={'row mt-4'}>
                        <div className={'col-md-5'}>
                            <button className="btn btn-primary sm w-100 pt-2 pb-2"
                                    disabled={formik.isSubmitting || !formik.isValid}
                                    type="submit">{!loading && (
                                <span className="indicator-label">
                                    Submit
                                </span>
                            )}
                                {loading && (
                                    <span
                                        className="indicator-progress"
                                        style={{display: "block"}}
                                    >
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                                )}</button>

                        </div>
                    </div>
                </form>

            </div>
        </div>
    );
};

export default RequestConsultationForm;
