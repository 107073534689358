import React, {useState} from "react";
import {useFormik} from "formik";
import clsx from "clsx";
import * as Yup from "yup";
import Select from 'react-select';
import {propertyRegistration} from "../core/requests";

const ListYourPropertyForm = () => {
    const [loading, setLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const initialValues = {
        email: "",
        name: "",
        type: "",
        phone_number: "",
        bedrooms: "",
        bathrooms: "",
        address: "",
        furnished: true,
        selling: true,

    };
    const types = [
        {value: '1', label: 'Apartment'},
        {value: '2', label: 'Villa'}
    ];
    const numbers = [
        {value: '1', label: '1'},
        {value: '2', label: '2'},
        {value: '3', label: '3'},
        {value: '4', label: '4'},
        {value: '5', label: '5'},
        {value: '6', label: '6'},
        {value: '7', label: '7'},
        {value: '8', label: '8'}
    ];

    const requestConsultationSchema = Yup.object().shape({
        type: Yup.string().required("Type is required"),
        email: Yup.string()
            .email("Wrong email format")
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Email is required"),
        name: Yup.string().required("Name is required"),
        phone_number: Yup.string().required("Phone number is required"),
        bedrooms: Yup.string().required("Number of Bedrooms is required"),
        bathrooms: Yup.string().required("Number of Bathrooms is required"),
        address: Yup.string().required("Property address is required"),

    });
    const formik = useFormik({
        initialValues,
        validationSchema: requestConsultationSchema,
        onSubmit: async (values, {setSubmitting}) => {
            setSubmitting(true);
            setLoading(true);
            try {


                const {data} = await propertyRegistration(values);
                //
                if (data === 1) {
                    setIsActive(true);
                    setLoading(false);

                    formik.resetForm();
                    setTimeout(function () {

                        // resetForm()
                    }, 3500);
                }
            } catch (ex) {
                console.error(ex);
            } finally {
                setSubmitting(true);
                setLoading(false);
            }
        },
    });
    return (
        <div className={'row justify-content-center form-property z-index-999'}>
            <div className={'col-lg-8 col-md-12 col-12 mt-3'}>
                <h2 className={'form-title text-center mt-5 mb-5 text-none'}>
                    To get started, please provide us with some basic information about you and your property.
                </h2>
            </div>
            <div className={'col-md-10'}>
                <div className={` ${isActive ? " alert-box d-block" : "alert-box d-none"}`}>
                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                        Successfully Done
                    </div>
                </div>
            </div>
            <div className={'col-lg-10 col-md-12 col-12'}>
                <form className="bg-white z-index-999 p-4 mb-5" onSubmit={formik.handleSubmit} noValidate>
                    <div className={'form-group row'}>
                        <label className={'form-label-secondary'}>Contact Information</label>
                        <div className={'col-md-4 mt-3'}>
                            <input type={'text'}  {...formik.getFieldProps("name")}
                                   className={clsx(
                                       "form-control  main-input bordered",
                                       {
                                           "is-invalid":
                                               formik.touched.name && formik.errors.name,
                                       },
                                       {
                                           "is-valid":
                                               formik.touched.name && !formik.errors.name,
                                       })} placeholder={'Name'}/>

                            {formik.touched.name && formik.errors.name && (
                                <div className="fv-plugins-message-container">
                                    <span role="alert">{formik.errors.name}</span>
                                </div>
                            )}
                        </div>
                        <div className={'col-md-4 mt-3'}>
                            <input type={'email'}  {...formik.getFieldProps("email")}
                                   className={clsx(
                                       "form-control  main-input bordered",
                                       {
                                           "is-invalid":
                                               formik.touched.email && formik.errors.email,
                                       },
                                       {
                                           "is-valid":
                                               formik.touched.email && !formik.errors.email,
                                       })} placeholder={'Email Address'}/>

                            {formik.touched.email && formik.errors.email && (
                                <div className="fv-plugins-message-container">
                                    <span role="alert">{formik.errors.email}</span>
                                </div>
                            )}
                        </div>

                        <div className={'col-md-4 mt-3'}>
                            <input type={'text'}  {...formik.getFieldProps("phone_number")}
                                   className={clsx(
                                       "form-control  main-input bordered",
                                       {
                                           "is-invalid":
                                               formik.touched.phone_number && formik.errors.phone_number,
                                       },
                                       {
                                           "is-valid":
                                               formik.touched.phone_number && !formik.errors.phone_number,
                                       })} placeholder={'Phone Number'}/>

                            {formik.touched.phone_number && formik.errors.phone_number && (
                                <div className="fv-plugins-message-container">
                                    <span role="alert">{formik.errors.phone_number}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={'form-group row mt-5'}>
                        <label className={'form-label-secondary'}>Property Information</label>

                        <div className={'col-md-4 mt-3'}>
                            <Select
                                className={clsx(
                                    "",
                                    {
                                        "invalid-select":
                                            formik.touched.type && formik.errors.type,
                                    },
                                    {
                                        "valid-select":
                                            formik.touched.type && !formik.errors.type,
                                    })}
                                closeMenuOnSelect={true}
                                placeholder={"Select Property type"}
                                options={types.map((e: {
                                    label: any;
                                    value: any
                                }) => ({label: e.label, value: e.value}))}
                                isSearchable={true}
                                onChange={(newValue: any) =>
                                    formik.setFieldValue('type', newValue.value)
                                }
                            />
                            {formik.touched.type && formik.errors.type && (
                                <div className="fv-plugins-message-container">
                                    <span role="alert">{formik.errors.type}</span>
                                </div>
                            )}
                        </div>

                        <div className={'col-md-8 mt-3'}>
                            <input type={'text'}  {...formik.getFieldProps("address")}
                                   className={clsx(
                                       "form-control  main-input bordered",
                                       {
                                           "is-invalid":
                                               formik.touched.address && formik.errors.address,
                                       },
                                       {
                                           "is-valid":
                                               formik.touched.address && !formik.errors.address,
                                       })} placeholder={'Property Address'}/>

                            {formik.touched.address && formik.errors.address && (
                                <div className="fv-plugins-message-container">
                                    <span role="alert">{formik.errors.address}</span>
                                </div>
                            )}
                        </div>


                    </div>
                    <div className={'form-group row mt-5 align-items-center'}>
                        <label className={'form-label-secondary'}>Quick Details</label>

                        <div className={'col-md-4 mt-3'}>
                            <Select
                                className={clsx(
                                    "",
                                    {
                                        "invalid-select":
                                            formik.touched.bedrooms && formik.errors.bedrooms,
                                    },
                                    {
                                        "valid-select":
                                            formik.touched.bedrooms && !formik.errors.bedrooms,
                                    })}
                                closeMenuOnSelect={true}
                                placeholder={"Number of Bedrooms"}
                                options={numbers.map((e: {
                                    label: any;
                                    value: any
                                }) => ({label: e.label, value: e.value}))}
                                isSearchable={true}
                                onChange={(newValue: any) =>
                                    formik.setFieldValue('bedrooms', newValue.value)
                                }
                            />
                            {formik.touched.bedrooms && formik.errors.bedrooms && (
                                <div className="fv-plugins-message-container">
                                    <span role="alert">{formik.errors.bedrooms}</span>
                                </div>
                            )}
                        </div>

                        <div className={'col-md-4 mt-3'}>
                            <Select
                                className={clsx(
                                    "",
                                    {
                                        "invalid-select":
                                            formik.touched.bathrooms && formik.errors.bathrooms,
                                    },
                                    {
                                        "valid-select":
                                            formik.touched.bathrooms && !formik.errors.bathrooms,
                                    })}
                                closeMenuOnSelect={true}
                                placeholder={"Number of Bathrooms"}
                                options={numbers.map((e: {
                                    label: any;
                                    value: any
                                }) => ({label: e.label, value: e.value}))}
                                isSearchable={true}
                                onChange={(newValue: any) =>
                                    formik.setFieldValue('bathrooms', newValue.value)
                                }
                            />
                            {formik.touched.bathrooms && formik.errors.bathrooms && (
                                <div className="fv-plugins-message-container">
                                    <span role="alert">{formik.errors.bathrooms}</span>
                                </div>
                            )}
                        </div>

                        <div className={'col-lg-2 mt-lg-2 mt-3'}>
                            <label className="radio">
                                <input name="radio" type="radio" checked={formik.values.furnished}
                                       onChange={() => formik.setFieldValue('furnished', true)}/>
                                <span className={'light-grey'}>Furnished</span>
                            </label>
                        </div>
                        <div className={'col-lg-2'}>
                            <label className="radio">
                                <input name="radio" type="radio"
                                       checked={!formik.values.furnished}
                                       onChange={() => formik.setFieldValue('furnished', false)}
                                />
                                <span className={'light-grey'}>Unfurnished</span>
                            </label>
                        </div>


                    </div>
                    <div className="form-group row mt-5">

                        <label className={'form-label-secondary'}>How Can We Help You?</label>
                        <div className={'interested mt-3 mb-3'}>
                            Interested in
                        </div>
                        <div className={'col-lg-3'}>
                            <label className="radio-2">
                                <input name="radio-2" type="radio" checked={formik.values.selling}
                                       onChange={() => formik.setFieldValue('selling', true)}/>
                                <span className={'light-grey'}>Selling my property</span>
                            </label>
                        </div>
                        <div className={'col-lg-3'}>
                            <label className="radio-2">
                                <input name="radio-2" type="radio"
                                       checked={!formik.values.selling}
                                       onChange={() => formik.setFieldValue('selling', false)}/>
                                <span className={'light-grey'}>Renting out my property</span>
                            </label>
                        </div>
                    </div>


                    <div className={'row mt-4 mb-4'}>
                        <div className={'col-md-2'}>
                            <button className="btn btn-primary sm w-100 "
                                    disabled={formik.isSubmitting || !formik.isValid}
                                    type="submit">{!loading && (
                                <span className="indicator-label">
                                    Submit
                                </span>
                            )}
                                {loading && (
                                    <span
                                        className="indicator-progress"
                                        style={{display: "block"}}
                                    >
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                                )}</button>

                        </div>
                    </div>
                </form>

            </div>
        </div>
    );
};

export default ListYourPropertyForm;
