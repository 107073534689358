import React, {useEffect} from "react";
import {SocialMeta} from "../../components/SocialMeta";
import {useHeaderContext} from "../../layout/context/HeaderContext";
import TopBanner from "../home/components/TopBanner";
import WhyChooseUs from "./components/WhyChooseUs";
import Contact from "./components/Contact";
import Mission from "./components/Mission";
import Philosophy from "./components/Philosophy";

const About: React.FC = () => {
    const {setVariant, setLogoColor} = useHeaderContext();

    useEffect(() => {
        // Set variant to "default" when component mounts (assuming homepage needs transparent background)
        setVariant("property");
        setLogoColor("dark");
        return () => {
            // Clean up or reset when component unmounts
            setVariant("property");
            setLogoColor("dark");
        };
    }, [setVariant, setLogoColor]);

    return (
        <>
            <SocialMeta
                title={'About'}
                description={'Have questions or need assistance? Reach out to Olive Branch through our Contact page. We\'re here to help with any inquiries, support, or feedback.'}
            />

            <TopBanner
                title={'<h1 class="title-2 fs-65 mb-0">About Us</h1>'}
            />

            <section id="about-section" className="about-section border-bottom">
                <div className="container mt-5 pt-3">
                    <div className="row align-items-center position-relative">
                        <div className="col-12">
                            <div className="dark-light-color fs-16 lh-25 f-main-medium text-center">
                                <p>
                                    Welcome to Olive Branch Properties, your premier boutique agency specializing in
                                    brokerage services across Dubai and the UAE. Founded by Thuraya Abdulla, who brings
                                    over
                                    25 years of living experience in the UAE and more than a decade in the real estate
                                    industry, Olive Branch Properties is built on a foundation of excellence and
                                    dedication
                                    to client satisfaction.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <Mission/>
                <Philosophy/>
                <WhyChooseUs/>
                <Contact/>
            </section>
        </>
    );
};

export default About;
