import React, {FC, useEffect, useState} from "react";
import {getBlog} from "../../core/home/requests";
import TopBanner from "../home/components/TopBanner";
import BlogCard from "../home/components/BlogCard";
import {SocialMeta} from "../../components/SocialMeta";
import {useHeaderContext} from "../../layout/context/HeaderContext";


const Blog: FC = () => {
    const [blogs, setBlogs] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [metaTags, setMetaTags] = useState<any>();
    const [page, setPage] = useState<number>(1);
    const {setVariant, setLogoColor} = useHeaderContext();
    const [paginationData, setPaginationData] = useState({
        last_page: 0,
        current_page: 0,
        total: 0,
    });

    useEffect(() => {
        // Set variant to "default" when component mounts (assuming homepage needs transparent background)
        setVariant("property");
        setLogoColor("dark")
        return () => {
            // Clean up or reset when component unmounts
            setVariant("property");
            setLogoColor("dark");
        };
    }, [setVariant, setLogoColor]);

    useEffect(() => {
        getBlog(1, 9).then((response) => {
            setBlogs(response.data.blog.data)
            setPaginationData(response.data.blog);
            setMetaTags(response.data.page)
        });
    }, []);

    const loadMoreBlogs = async () => {
        setLoading(true)
        try {
            getBlog(page + 1, 9).then(
                (response) => {
                    setBlogs((prevBlogs: any[]) => {
                        const newBlogs = response.data.blog.data || [];
                        return Array.isArray(prevBlogs) ? [...prevBlogs, ...newBlogs] : newBlogs;
                    });
                    setPage(prevPage => prevPage + 1);
                    setLoading(false)
                }
            );

        } catch (error) {

        }
    };

    return (
        <>
            <SocialMeta
                title={metaTags && metaTags.meta_title ? metaTags.meta_title : 'Olive Branch Properties: Real Estate Specialists in Dubai'}
                description={metaTags && metaTags.meta_description ? metaTags.meta_description : 'Find your dream property in Dubai! Explore luxury apartments, villas, & off-plan projects. Expert agents. Contact Olive Branch Properties today!'}/>
            <TopBanner title={'<h1 class="title-2 fs-65">Blog</h1>'}/>
            <div className={'bg-primary-light'}>
                <div className={'container'}>
                    {blogs?.length > 0 && (
                        <>
                            <div className="row pt-4">
                                <div className="col-xl-8 col-lg-12 col-md-12 mb-3">
                                    <div className="row">
                                        {blogs.slice(0, 3).map((article: any, index: any) => (
                                            <BlogCard
                                                key={index}
                                                article={article}
                                                btnClass={`btn btn-primary${index === 0 ? ' lg' : '-inverse'}`}
                                                dateClass="date sm"
                                                titleClass={`title-${index === 0 ? 'smm' : 'sm'}`}
                                                colClass={`col-xl-${index === 0 ? '12' : '6'} col-lg-12 col-md-12 mb-${index === 0 ? '4' : '3'}`}
                                                withDescription={index === 0}
                                                imageHeight={`${index === 0 ? 'h-450px' : 'h-200px'}`}
                                                paddingClass="sm"
                                                heightBlock={`${index === 0 ? 'h-280px' : "h-160px"}`}
                                                bigCard={(index === 0)}
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-12 col-md-12">
                                    <div className="row">
                                        {blogs.slice(3, 6).map((article: any, index: any) => (
                                            <BlogCard
                                                key={index + 3}
                                                article={article}
                                                btnClass="btn btn-primary-inverse"
                                                dateClass="date sm"
                                                titleClass="title-sm"
                                                colClass="col-xl-12 col-lg-12 col-md-12 mb-3"
                                                withDescription={false}
                                                imageHeight="h-200px"
                                                paddingClass="sm"
                                                heightBlock="h-160px"
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="row pb-4">
                                {blogs.slice(6, 7).map((article: any, index: any) => (
                                    <BlogCard
                                        key={index + 6}
                                        article={article}
                                        btnClass="btn btn-primary lg"
                                        dateClass="date"
                                        bigCard={true}
                                        titleClass="title-smm"
                                        colClass="col-xl-8 col-lg-12 col-md-12 mb-3 order-2"
                                        withDescription={true}
                                        imageHeight="h-450px"
                                        heightBlock="h-280px"
                                    />
                                ))}
                                <div className="col-xl-4 col-lg-12 col-md-12 order-1">
                                    <div className="row">
                                        {blogs.slice(7, 9).map((article: any, index: any) => (
                                            <BlogCard
                                                key={index + 7}
                                                article={article}
                                                btnClass="btn btn-primary-inverse"
                                                dateClass="date sm"
                                                titleClass="title-sm"
                                                colClass="col-xl-12 col-lg-12 col-md-12 mb-3"
                                                withDescription={false}
                                                imageHeight="h-200px"
                                                paddingClass="sm"
                                                heightBlock="h-160px"
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className={'pb-lg-5 mb-3'}>
                                <div className="row">
                                    {blogs && blogs.length > 9 && blogs.slice(9).map((blog: any, index: any) => (
                                        <BlogCard article={blog} btnClass={'btn btn-primary-inverse'} key={index}
                                                  dateClass={'date sm'} titleClass={'title-sm'}
                                                  colClass={'col-xl-4 col-lg-4 col-md-12 mb-4'}
                                                  withDescription={false} imageHeight={'h-200px'} paddingClass={'sm'}
                                                  heightBlock={'h-160px'}/>
                                    ))}
                                </div>
                                {blogs.length < paginationData.total &&
                                    <div className="row justify-content-center">
                                        <div className="col-lg-3">

                                            {!loading && (
                                                <button onClick={loadMoreBlogs} className="btn btn-primary lg w-100">

                                                    Load more

                                                </button>
                                            )}
                                            {loading && (
                                                <button className="btn btn-primary lg w-100">
                                                    <span
                                                        className="indicator-progress"
                                                        style={{display: "block"}}
                                                    >
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                }
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}
export default Blog;