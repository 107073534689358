import React, {useEffect, useState} from "react";
import {indexProperties} from "./core/requests";
import Resale from "./components/Resale";
import {useHeaderContext} from "../../layout/context/HeaderContext";
import {SocialMeta} from "../../components/SocialMeta";


const PropertyIndex = () => {
    const [cityFirst, setCityFirst] = useState<any>();
    const [selectedPage, setSelectedPage] = useState<number>(0);
    const [cities, setCities] = useState<any>([]);
    const [paginationData, setPaginationData] = useState({
        last_page: 0,
        current_page: 0,
        total: 0,
    });
    const {setVariant, setLogoColor} = useHeaderContext();
    useEffect(() => {
        // Set variant to "default" when component mounts (assuming homepage needs transparent background)
        setVariant("property");
        setLogoColor("dark")
        return () => {
            // Clean up or reset when component unmounts
            setVariant("property");
            setLogoColor("dark");
        };
    }, [setVariant, setLogoColor]);
    useEffect(() => {
        indexProperties(1, 9).then(response => {

            setCities(response?.data?.cities)
            setCityFirst(response?.data?.city_first)
            setPaginationData(response.data.city_first.resale_leasing.meta)
            setSelectedPage(1)
        })

    }, []);

    return (
        <>
            <SocialMeta
                title={'Olive Branch Properties: Real Estate Specialists in Dubai'}
                description={'Find your dream property in Dubai! Explore luxury apartments, villas, & off-plan projects. Expert agents. Contact Olive Branch Properties today!'}/>

            {cities && cityFirst &&
                <Resale cities={cities} cityFirst={cityFirst} selectedPage={selectedPage} pagination={paginationData}/>}

        </>
    );
};

export default PropertyIndex;
