/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC} from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Blog from "../pages/blog/Blog";
import BlogShow from "../pages/blog/BlogShow";
import PropertyDetails from "../pages/property/Property";
import Page404 from "../pages/404";
import App from "../../App";
import Contact from "../pages/contact/Contact";
import TagBlogs from "../pages/blog/TagBlogs";
import Home from "../pages/home/Home";
import PropertyIndex from "../pages/property/PropertyIndex";
import ListYourProperty from "../pages/property/ListYourProperty";
import PropertyShow from "../pages/property/PropertyShow";
import Faq from "../pages/faq/Faqs";
import Faqs from "../pages/faq/Faqs";
import About from "../pages/about/About";
import Privacy from "../pages/privacy/privacy";


/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */


const AppRoutes: FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<App/>}>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/blog" element={<Blog/>}/>
                    <Route path="/blog/tag/:slug" element={<TagBlogs/>}/>
                    <Route path="/blog/:slug" element={<BlogShow/>}/>
                    <Route path="/properties" element={<PropertyIndex/>}/>
                    <Route path="/properties/:slug" element={<PropertyShow/>}/>
                    <Route path="/property/:slug" element={<PropertyDetails/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/faqs" element={<Faqs/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/privacy-policy" element={<Privacy/>}/>
                    <Route path="/list-your-property" element={<ListYourProperty/>}/>
                    <Route path="*" element={<Page404/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export {AppRoutes}
