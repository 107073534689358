import axios from 'axios'
import {API_URL} from "../../helpers/crud-helper/consts";


const CONTACT_US_URL = `${API_URL}/contact`
const HOME_URL = `${API_URL}/home`
const CITY_URL = `${API_URL}/city`
const PROJECT_URL = `${API_URL}/project`
const BLOG_URL = `${API_URL}/blog`

const contact = (value: any): Promise<any> => {
    return axios
        .post(CONTACT_US_URL, value)
        .then((response) => response)
}

const getHomeData = (): Promise<any> => {
    return axios
        .get(HOME_URL)
        .then((response) => response)
}
const showCity = (slug: any, page: number, limit: number): Promise<any> => {
    return axios
        .get(`${CITY_URL}/${slug}?page=${page}&limit=${limit}`)
        .then((response) => response)
}

const showProjectMapping = (slug: any): Promise<any> => {
    return axios
        .get(`${PROJECT_URL}/${slug}`)
        .then((response) => response)
}

const showProject = (slug: any): Promise<any> => {
    return axios
        .get(`${PROJECT_URL}/projects/${slug}`)
        .then((response) => response)
}
const getBlog = (page: any, limit: any): Promise<any> => {
    return axios
        .get(`${BLOG_URL}?page=${page}&limit=${limit}`)
        .then((response) => response)
}
const getBlogTag = (slug: any, page: any, limit: any): Promise<any> => {
    return axios
        .get(`${BLOG_URL}/tag/${slug}?page=${page}&limit=${limit}`)
        .then((response) => response)
}
const showBlog = (slug: any): Promise<any> => {
    return axios
        .get(`${BLOG_URL}/${slug}`)
        .then((response) => response)
}

const comment = (value: any): Promise<any> => {
    return axios
        .post(`${API_URL}/comment`, value)
        .then((response) => response)
}
export {contact, getHomeData, showCity, showProjectMapping, getBlog, showBlog, comment, getBlogTag, showProject}
