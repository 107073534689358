import React, {useState} from "react";
import {useFormik} from "formik";
import clsx from "clsx";
import * as Yup from "yup";
import {contact} from "../../../core/home/requests";


const Contact = () => {
    const [loading, setLoading] = useState(false);

    const initialValues = {
        email: "",
        name: "",
        message: "",
        type: 4

    };

    const contactSchema = Yup.object().shape({
        email: Yup.string()
            .email("Wrong email format")
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Email is required"),
        name: Yup.string().required("Name is required"),
        message: Yup.string().required("Description is required"),
    });
    const formik = useFormik({
        initialValues,
        validationSchema: contactSchema,
        onSubmit: async (values, {setSubmitting}) => {
            setSubmitting(true);
            try {
                setLoading(true);

                const {data} = await contact(values);

                if (data === 1) {
                    setLoading(false);
                    formik.resetForm();
                    setTimeout(function () {
                        // resetForm()
                    }, 3500);
                }
            } catch (ex) {
                console.error(ex);
            } finally {
                setSubmitting(true);
                setLoading(false);
            }
        },
    });
    return (
        <div className={'bg-aside-form mt-5  bdr-lg-tb'}>
            <div className={'p-4'}>
                <div className={'form-title'}>
                    Contact Us
                </div>
                <form className="z-index-999"
                      onSubmit={formik.handleSubmit}
                      noValidate>
                    <div className={'form-group row mt-4'}>
                        <div className={'col-md-12'}>
                            <input type={'text'}  {...formik.getFieldProps("name")}
                                   className={clsx(
                                       "form-control  main-input bordered",
                                       {
                                           "is-invalid":
                                               formik.touched.name && formik.errors.name,
                                       },
                                       {
                                           "is-valid":
                                               formik.touched.name && !formik.errors.name,
                                       })} placeholder={'Name'}/>

                            {formik.touched.name && formik.errors.name && (
                                <div className="fv-plugins-message-container">
                                    <span role="alert">{formik.errors.name}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={'form-group row mt-4'}>
                        <div className={'col-md-12'}>
                            <input type={'email'}
                                   {...formik.getFieldProps("email")}
                                   className={clsx(
                                       "form-control main-input bordered",
                                       {
                                           "is-invalid":
                                               formik.touched.email && formik.errors.email,
                                       },
                                       {
                                           "is-valid":
                                               formik.touched.email && !formik.errors.email,
                                       })}
                                   placeholder={'Email Address'}/>

                            {formik.touched.email && formik.errors.email && (
                                <div className="fv-plugins-message-container">
                                    <span role="alert">{formik.errors.email}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={'form-group row mt-4'}>
                        <div className={'col-md-12'}>
                        <textarea     {...formik.getFieldProps("message")} className={clsx(
                            "form-control main-input bordered h-200",
                            {
                                "is-invalid":
                                    formik.touched.message && formik.errors.message,
                            },
                            {
                                "is-valid":
                                    formik.touched.message && !formik.errors.message,
                            })} placeholder={'Message'}/>

                            {formik.touched.message && formik.errors.message && (
                                <div className="fv-plugins-message-container">
                                    <span role="alert">{formik.errors.message}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={'row mt-4'}>
                        <div className={'col-md-6'}>
                            <button className="btn btn-primary lg w-100"
                                    disabled={formik.isSubmitting || !formik.isValid}
                                    type="submit">{!loading && (
                                <span className="indicator-label">
                                    Submit
                                </span>
                            )}
                                {loading && (
                                    <span
                                        className="indicator-progress"
                                        style={{display: "block"}}
                                    >
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                                )}</button>

                        </div>
                    </div>
                </form>

            </div>
        </div>
    );
};

export default Contact;
